import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { Container, Button, HeroCover, Header } from "../components/Lib"
import SEO from "../components/seo"

import Slider from "../components/Slider"
import Section from "../components/lib/Section"
import WhereToListen from "../components/WhereToListen"
import Footer from "../components/Footer"

import { transformEpisodesData, transformPostsData } from "../utils/helpers"

const IndexPage = ({ data }) => {
  console.log(data.allEpisodes)
  const episodes = transformEpisodesData(data.allEpisodes.edges)
  const posts = transformPostsData(data.allPosts.edges)
  return (
    <Container>
      <SEO
        title="پادکست زندگینامه افراد موفق"
        description="ما در پادکست فارسی بایوکست، داستان زندگی افراد موفقی رو تعریف می‌کنیم که
            بر موانع و سختی‌های زندگیشون چیره شدن و تاثیر بزرگی از خودشون به جا
            گذاشتند."
        keywords={[
          "biocast",
          "بایوکست",
          "پادکست زندگینامه",
          "زندگینامه افراد موفق",
        ]}
      />
      <Header pushed className="header--lightgray header--home" />
      <HeroCover lastEpisode={episodes[0]} />
      <WhereToListen />
      <Section>
        <div className="section__header">
          <h2 className="latest_episodes__title">جدیدترین اپیزود‌ها</h2>
          <Button
            Tag="link"
            to="/episodes/"
            className="latest_episodes__more"
            size="small"
            color="gray-outlined"
            iconName="list"
          >
            مشاهده همه اپیزود‌ها
          </Button>
        </div>
        <Slider type="episode" items={episodes.slice(1)} />
      </Section>
      <Section backgroundColor="var(--lightgray)" className="latest_posts">
        <div className="section__header">
          <h2 className="latest_episodes__title">جدیدترین نوشته‌ها</h2>
          <Button
            className="latest_episodes__more"
            size="small"
            color="gray-outlined"
            iconName="list"
            Tag="link"
            to="/blog/all"
          >
            مشاهده همه نوشته‌ها
          </Button>
        </div>
        <Slider type="post" items={posts} />
      </Section>
      <Footer />
    </Container>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allEpisodes: allStrapiEpisode(
      limit: 5
      sort: { fields: episodeNumber, order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          created_at
          id
          title
          strapiId
          mainImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          squareImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          description
          text
          episodeNumber
        }
      }
    }
    allPosts: allStrapiPost(
      limit: 4
      sort: { fields: created_at, order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          title
          description
          content
          mainImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          content
          created_at
          imagePosition
        }
      }
    }
  }
`
