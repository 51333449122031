import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import {
  ListenToButton,
  ReadButton,
  EpisodeCard,
  PostCard,
  Icon,
  Button,
} from "./Lib"

// const initialState = {
//   slides: [],
//   index: 0,
// }

// const sliderReducer = (state, action) => {
//   switch (action.type) {
//     case "NEXT":
//       return {
//         ...state,
//         index: (state.index + 1) % state.slides.length,
//       }
//     case "PREVIOUS":
//       return {
//         ...state,
//         index: state.index - 1 < 0 ? state.slides.length : state.index - 1,
//       }
//   }
// }

const Slider = ({ items, counts, type }) => {
  items = items.slice(0, 4)
  const types = {
    post: {
      card: PostCard,
      button: ReadButton,
      route: "blog",
      content: "content",
    },
    episode: {
      card: EpisodeCard,
      button: ListenToButton,
      route: "episode",
      content: "text",
    },
  }
  const Type = types[type]

  const [slide, setSlide] = useState(0)

  const sliderTitle = useRef()
  const sliderPattern = useRef()
  const nextRef = useRef()
  const previousRef = useRef()

  // useEffect(() => {
  //   const sliderInterval = setInterval(next, 10000)
  //   return () => {
  //     clearInterval(sliderInterval)
  //   }
  // }, [])
  const next = () => {
    setSlide(slide => (slide + 1) % items.length)
  }

  const previous = () => {
    setSlide(prev => (prev === 0 ? items.length - 1 : prev - 1))
  }

  return (
    <div className={`${type}s_slider`}>
      <div className="slider">
        <div className="slider__current">
          <div className="slider__information">
            <Link
              to={`/${Type.route}/${items[slide].slug}`}
              className="navigator"
            >
              <h3 className="slider__title" ref={sliderTitle}>
                <span>{items[slide].title}</span>
              </h3>
            </Link>
            <Type.button
              to={`/${Type.route}/${items[slide].slug}`}
              Tag="Link"
              block
            />
          </div>
          <div className="slider__pattern" ref={sliderPattern}>
            {items[slide][Type.content]}
          </div>
        </div>
        <div className="slider__slides">
          {items.map((item, index) => {
            let offset =
              (index - slide >= 0
                ? index - slide
                : index - slide + items.length) * 266
            return (
              <Type.card
                // 266 = card with  + margin => 250 + 16
                style={{
                  transform: `translate3d(${
                    Type.route == "episode" ? "-" : ""
                  }${offset}px,0,0)`,
                }}
                tag="Link"
                key={index}
                index={index}
                id={item.id}
                item={item}
                current={index === slide}
                overlayColor="--black"
                offset={offset}
              />
            )
          })}
        </div>
      </div>
      <div className="slider__buttons">
        <button className="slider__button" onClick={next} ref={nextRef}>
          <Icon name="arrow_right" />
        </button>
        <button className="slider__button" onClick={previous} ref={previousRef}>
          <Icon name="arrow_left" />
        </button>
      </div>
    </div>
  )
}

export default Slider
