import React from "react"
import {
  SnakePanel,
  App_ApplePodcast,
  App_CastBox,
  App_Spotify,
  App_Overcast,
  App_GooglePodcast,
} from "./Lib"

const WhereToListen = () => {
  return (
    <SnakePanel color="gradient">
      <div className="listen_to">
        <div className="listen_to__title">از کجا بشنویم؟</div>
        <div className="listen_to__logos">
          <a
            className="navigator"
            target="_blank"
            href="https://castbox.fm/channel/BioCast-%7C-%D8%A8%D8%A7%DB%8C%D9%88%DA%A9%D8%B3%D8%AA-id2769822?country=ir"
          >
            <App_CastBox />
          </a>
          <a
            className="navigator"
            target="_blank"
            href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy83Yzg4ZTg4L3BvZGNhc3QvcnNz"
          >
            <App_GooglePodcast />
          </a>
          <a
            className="navigator"
            target="_blank"
            href="https://open.spotify.com/show/4MMISSUGttGxTZp57mi5YC"
          >
            <App_Spotify />
          </a>
          <a
            className="navigator"
            target="_blank"
            href="https://podcasts.apple.com/us/podcast/biocast-%D8%A8%D8%A7%DB%8C%D9%88%DA%A9%D8%B3%D8%AA/id1443799089"
          >
            <App_ApplePodcast />
          </a>
          <a
            className="navigator"
            target="_blank"
            href="https://overcast.fm/itunes1443799089/biocast"
          >
            <App_Overcast />
          </a>
        </div>
      </div>
    </SnakePanel>
  )
}

export default WhereToListen
